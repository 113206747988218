/* License : MIT, @author: Vikas Kukreti, @github: https://github.com/vikas-kukreti */
* {
	margin:0;
	padding:0;
	outline: none;
}


/* Slider Design */

.slider {
	position: relative;
    overflow: hidden;
    height: 32.5rem;
	background-color: $bg-brand-light;
}
.slider .slider-item {
	display: block;
	max-height: 100vh;
	overflow: hidden;
	// background: #000000;
	background-color: $bg-brand-light;
	position: absolute;
	left: -100%;
	width: 100%;
	opacity: .5;
	transition: 1s left ease, 1s opacity ease;
}
.slider .slider-item.close {
	opacity: 0.5;
	left: 100%;
}
.slider .slider-item.show {
	z-index: 10;
	opacity: 1;
	left: 0;
}
.slider .background {
	display: block;
	width: 100%;
	max-height: 90vh;
}
.slider .slider-item > img {
	display: block;
	width: 100%;
}
.slider .switch {
	z-index: 11;
	display: block;
	position: absolute;
	bottom: 5%;
	left: 50%;
	transform: translate(-50%, 0);
}
.slider .switch i {
	float: left;
	content: " ";
	display: block;
	width: 1.5rem;
	height: 0.25rem;
	border-radius: 0.25rem;
	box-shadow: 0 0.125rem 0.125rem rgba(40, 40, 40, 0.4);
	background: rgba(255, 255, 255, 0.322);
	margin: 0.125rem;
	transition: 0.7s all;
}
.slider .switch i.active {
	background: rgba(255, 255, 255, 0.726);
}
.slider .prev,
.slider .next {
	z-index: 11;
	position: absolute;
	top: calc(50% - 1.4rem);
	height: 1.4rem;
	width: 1.4rem;
	padding: 1.5rem;
	border: none;
	border-radius: 50%;
	background: rgba(17, 17, 17, 0.333);
	transition: 0.4s all;
	opacity: 0.7;
}
.slider .prev svg,
.slider .next svg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1rem;
	height: 1rem;
	fill: rgba(255, 255, 255, 0.8);
	transition: 0.3s all;
}
.slider .prev {
	left: 3%;
	transform: rotate(180deg);
}
.slider .next {
	right: 3%;
}
.slider .prev:hover,
.slider .next:hover {
	background: rgba(0, 0, 0, 0.8);
	cursor: pointer;
}
.slider .prev:hover svg,
.slider .next:hover svg {
	fill: rgba(255, 255, 255, 1);
}

/* Responsive Slider Styles */
@media (max-width: $break-desktop) {
	.slider {
		height: 25rem;
	}
	.slider .prev,
	.slider .next {
		padding: 1rem;
	}
}