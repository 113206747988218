/**
 * Print
 */
@media print {
    /**
     * Pagebreak
     */
    h1,
    h2,
    h3 {
        page-break-after: avoid;
    }

    /**
     * Links
     */
    #content a:not([role]):not([data-action]):not([data-link=media])::after {
        font-size: .875rem;
        font-style: italic;

        content: ' (' attr(href) ')';
        text-transform: none;
    }

    /**
     * Hidden
     */
    #menu,
    #footer,
    .gallery {
        display: none;
    }
}