[data-url^="/jetzt_bewerben"], [data-url^="/kontakt"] {
    #content {
        div[data-attr="content"] {
            margin-left: 3.5rem;
        }
    }
    form[class*="form"] {
         margin-left: 3.5rem;
    }
}

@media screen and (max-width: $break-desktop-header) {
    [data-url^="/jetzt_bewerben"], [data-url^="/kontakt"] {
        #content {
            div[data-attr="content"] { 
                margin-left: .5rem;
                margin-right: .5rem;
            }
        }
        #main {
            form {
                margin-left: .5rem;
                margin-right: .5rem;
                fieldset {
                    padding: $line-height/2;
                    & + fieldset {
                        padding-top: 0;
                    }
                    ul {
                        padding-left: $line-height/2;
                    }
                    .block-notice {
                        margin: 0 !important;
                    }
                    button[type="submit"] {
                        width: 100%;
                    }
                }
            }
        }
    }
}