/**
 * Footer
 */

#footer {
    padding: 1.5rem .75rem 2rem;
    padding: 0.75rem .75rem 0;

    background: $bg-brand;
        font-size: .8rem;

        color: $font-color-pale;
        font-weight: 400;


    a {
        font-size: .8rem;
        font-weight: bold;

        display: inline-block;

        padding: 0;

        color: $font-color-pale;
        &:hover {
            text-decoration: underline;
        }
    }
    ul {

        max-width: $max-width-footer;
        margin: 0 auto ;
        padding: 0;

        list-style: none;

        text-align: center;

        &.social {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            padding-bottom: 1rem;
            li {
                padding: 0 1rem;
            }
            a {
                display: block;

                margin: 0;
                padding: .75rem;

                text-align: center;

                color: $font-color-link;
                border-radius: 50%;
                background: $bg-footer-icon;
                &:hover {
                    text-decoration: none;

                    background: darken(#33bee9, 10%);
                }
                img {
                    width: 1.5rem;

                    color: $font-color-link;
                    float: left;
                }
                &:after {
                    content: ".";
                    clear: both;
                    display: block;
                    visibility: hidden;
                    height: 0px;
                }
            }
        }
    }
}

@media screen and (min-width: $break-desktop) {
    footer {
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            margin: 0 auto;
            &.social {
                justify-content: center;
                li {
                    flex: 0 0 auto;

                    padding: 0 .5rem;
                }
            }
        }
    }
}