/**
 * Header
 */

// Design
// -----------------------------------------------------------------------
#header-wrap {
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: none;
    transition: all 100ms ease;
    #header {
        //opacity: .3;
        position: relative;
        z-index: 200;

        max-width: $max-width;
        height: auto;
        margin: 0 auto;
        padding: .5rem .5rem .5rem;
        transition: all 300ms ease;

        background: #fff;
        transition: all 100ms ease;
        > * {
            margin: 0;
        }
        .logo {
            img {
                margin: 0;
                transition: all 100ms ease;
                &.logo-brand {
                    //height: 4.65rem;
                    margin-right: 8%;
                }
                &.logo-wappen {
                    //height: 3.9rem;
                }
            }
        }
        .search {
            padding-right: 1rem;
            form {
                max-width: 17rem;
                min-height: 10px;
                padding: .5rem .7rem;

                background: $bg-brand-light;
                input {
                    min-width: 0;
                }
                button {
                    color: $bg-brand-pale;
                }
            }
            .toggle-contrast, .toggle-search, .goto-home, .einfache-sprache {
                color: $bg-gray;
                height: 1.5rem;
                width:auto;
                margin: 0 1rem 0 2rem; 
                svg {
                    height: 100%;
                    width: auto;
                    float:left;
                    // margin-left: .4rem;
                }
            }
            .toggle-contrast, .toggle-search, .einfache-sprache {
                margin: 0 1rem 0 1rem; 
            }
            .goto-home {
                margin: 0 1rem 0 2rem; 
            }
        }
    }
    &.sticky {
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.23);
        // transition: all 300ms ease;
        #header {
            // padding: .2rem .5rem .2rem;
            // height: auto;
            // transition: all 300ms ease;
            .logo {
                img {
                    // transition: all 300ms ease;
                    //&.logo-brand {
                    //    height: 4.65rem;
                    //    transition: all 300ms ease;
                    //}
                    //&.logo-wappen {
                    //    height: 3.9rem;
                    //    transition: all 300ms ease;
                    //}
                }
            }
        }
    }
}
// Design-MediaQuery
@media screen and (max-width: $break-desktop-header) {
    #header-wrap {
        #header {
            height: auto;
            padding-top: .2rem;
            .logo {
                max-width: 100%;
                margin-bottom: 1rem;
            }
            .search {
                padding-right: 0;
                .toggle-contrast, .toggle-search, .einfache-sprache {
                    margin: 0 .5rem;
                }
                .goto-home {
                    margin: 0;
                    margin: 0 .5rem 0 0;
                    svg {
                        // margin: 0 .4rem 0 0;
                    }
                }
            }
            .toggle {
                padding: 0 1rem;
                color: #fff;
            }
        }
    }
}
// -----------------------------------------------------------------------



// Flexbox
// -----------------------------------------------------------------------
#header-wrap {
    #header {
        display: flex;
        align-content: stretch;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-start;
        .logo {
            flex: 1 1 auto;

            max-width: 31.5rem;
            // Logo-Flex
            display: flex;
            align-items: center;
            flex-flow: row-reverse;
            justify-content: flex-start;
            // img {
                .logo-brand, .logo-wappen {
                    img {
                        max-height: 3.875rem;
                        width: 100%;
                        height: auto;
                    }
                }
                .logo-brand {
                    flex: 0 1 29.424%;
                    max-width: 8.057rem;
                    // height: 3.875rem;
                    // width: auto;
                    img {
                        width: 70%;
                    }
                }
                .logo-wappen {
                    flex: 0 1 70.576%;
                    max-width: 19.326rem;
                    // img {
                    //     height: 3.875rem;
                    //     width: auto;
                    // }
                }
            // }
        }
        .search {
            flex: 1 1 auto;

            display: flex;
            align-items: center;

            // min-width: 10rem;
            padding-left: 2rem;
            form {
                display: flex;
                flex-grow: 1;
                align-items: stretch;
                justify-content: flex-start;
                order: 1;
                input {
                    //flex: 1 1 auto;
                    flex-grow: 1;
                    width: 10rem;
                }
                button {
                    flex: 0 1 auto;
                }
            }
            .toggle-contrast {
                flex: 0 1 auto;
                order: 3;
            }
            .einfache-sprache {
                flex: 0 1 auto;
                order: 4;
            }
            .toggle-search {
                display: none;
            }
            .goto-home {
                flex: 0 1 auto;
                order: 2;
            }
        }
        .toggle {
            a {
                // Toggle-Flex
                display: flex;
                align-items: center;
                flex: 0 0 2.75rem;
                flex-flow: column wrap;
                justify-content: center;
                padding: 0;
            }
        }
    }
}
// Flex-MediaQuery
@media screen and (max-width: $break-desktop-header) {
    #header-wrap {
        #header {
            flex-wrap: wrap;
            .logo {
                flex: 1 1 100%;
                order: 1;
                max-width: 100%;
                justify-content: space-between;
            }
            .toggle {
                order: 2;
            }
            .search {
                padding-left: 0;
                order: 3;
                form {
                    width: 100%;
                    max-width: none;
                    order: 2;
                    margin-top: 0.75rem;
                    input {
                        width: auto;
                    }
                }
                .toggle-contrast {
                    flex: 0 1 auto;
                    order: 3;
                }
                .toggle-contrast {
                    flex: 0 1 auto;
                    order: 4;
                }
                .goto-home {
                    flex: 0 1 auto;
                    order: 1;
                }
            }
        }
    }
}
@media screen and (max-width: $break-desktop-header) {
    #header-wrap {
        #header {
            .search {
                flex-wrap: wrap;
                form {
                    order: 4;
                    margin-top: 0.75rem;
                    display: none;
                }
                .toggle-contrast {
                    flex: 0 1 auto;
                    order: 2;
                }
                .goto-home {
                    flex: 0 1 auto;
                    order: 1;
                }
                .toggle-search {
                    display: block;
                    order: 4;
                }
                .einfache-sprache {
                    display: block;
                    order: 3;
                }
                .show-search + form {
                    display: flex;
                }
            }
        }
    }
}
// -----------------------------------------------------------------------




// Toggle-Button
// -----------------------------------------------------------------------
#header {
    .toggle {
        font-size: .8rem;
        font-weight: bold;
        line-height: 1.5rem;

        margin-right: 2rem;
        cursor: pointer;

        color: $font-color-link;
        a {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;

            width: 2.25rem;
            height: 1.55rem;
            padding: .5em 1em;
            span {
                position: relative;

                display: -webkit-box;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;

                width: 100%;
                height: .25rem;

                -webkit-transition: all .4s ease;
                        transition: all .4s ease;

                border-radius: 2px;
                background: $font-color-link;
                &:after,
                &:before {
                    position: absolute;
                    z-index: 1;
                    top: -.5rem;
                    left: 0;

                    width: 100%;
                    height: .25rem;

                    content: '';
                    -webkit-transition: all .4s ease;
                            transition: all .4s ease;

                    border-radius: 2px;
                    background: inherit;
                }
                &:after {
                    top: .5rem;
                }
            }
        }

        
        &[data-toggle] {
            a {
                span {
                    transform: rotate(135deg);

                    &:before,
                    &:after {
                        top: 0;

                        transform: rotate(90deg);
                    }

                    &:after {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                a span {
                    transform: rotate(225deg);
                }
            }
        }
    }
}
@media screen and (max-width: $break-desktop-header) {
    #header {
        .toggle {
            text-indent: -99999px;
            line-height: 0;
            a {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
// -----------------------------------------------------------------------
