#accept-ds {
    position: fixed;
    bottom: 0;
    padding: 2rem 5rem;
    // height: 50vh;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    background: #ffffff;
    width: 60%;
    z-index: 1000;
    margin: 0 auto;
    box-shadow: 0px -10px 10px 0px rgba(0,0,0,0.23);
    left: 50%;
    transform: translateX(-50%);
    button {

        color: $font-color-pale;
        padding: 0.5*$line-height 0.5*$line-height;
        font-weight: bold;
        border-radius: 1px;
        text-decoration: none;
        //margin: $line-height/2 0;
        display: inline-block;
        background: $bg-brand;
        &:hover {
            background: darken($bg-brand, 5%);
        }
    }
}



@media screen and (max-width: $break-desktop) {
    #accept-ds {
        width: 100%;
        padding: .5rem;
        border-radius: 0;
    }
}
