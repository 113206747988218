/**
 * Navigation
 */

#header-menu-wrap {
    display: block;
    position: absolute;
    left: 0;
    background: #fff;
    z-index: 100;
    top: -9999px;
    min-height: 0;
    overflow: hidden;
    padding: 1rem 1.5rem;
    transition: all 300ms ease-in-out;
    box-shadow: 0px 15px 10px 0px rgba(0,0,0,0.25);
    opacity: 0;
    nav {
            a {
                display: block;
                padding: .5rem 0;
                color: $font-color-link;
                &:hover {
                    text-decoration: underline;
                }
            }
    }

    .social {
        background: #fff;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-top: 1rem;
        a {
            border-radius: 50%;
            display: block;

            margin-right: 1rem;
            padding: .8rem;

            text-align: center;
            align-self: start;

            color: $bg-brand;
            background: $bg-footer-icon;
            line-height: 1rem;
            &:hover {
                text-decoration: none;

                background: darken($bg-footer-icon, 10%);
            }
            img {
                width: 1rem;

                color: $bg-brand;
            }
        }
    }
}

#header-menu-wrap[data-toggle='open'] {
    display: block;
    opacity: 1;
    top: 100%;
}


@media screen and (max-width: $break-desktop-header) {
    #header-menu-wrap[data-toggle='open'] {
        width: 100%;
    }
}