/**
 * Font
 */
@font-face {
  font-family: fontawesome;
  font-style: normal;
  font-weight: normal;
  src: url(font/fa-regular.woff2) format('woff2'), url(font/fa-regular.woff) format('woff');
}

@font-face {
  font-family: fontawesome;
  font-style: normal;
  font-weight: 900;
  src: url(font/fa-solid.woff2) format('woff2'), url(font/fa-solid.woff) format('woff');
}

/**
 * Screen
 */
@media screen {
  /**
   * Actions
   */
  a[data-action] {
    display: inline-block;
    line-height: 1;
    font-family: fontawesome;
    font-size: 0;
    font-weight: 900;
  }

  a[data-action]::before {
    font-size: 1rem;
  }

  /**
   * Navigation
   */
  nav[data-toggle] {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  nav[data-toggle=open] {
    position: relative;
  }

  nav[data-toggle] ul {
    display: none;
    margin-left: 1.25rem;
  }

  nav[data-toggle] > ul {
    margin-top: 1.5rem;
  }

  nav[data-toggle] a[data-action=toggle][data-toggle] ~ ul {
    display: block;
  }

  nav[data-toggle] > a[data-action=toggle] {
    display: block;
    font-size: 1.5rem;
  }

  nav[data-toggle] ul a[data-action=toggle] {
    display: inline-block;
    margin: 0 0.75rem 0 -1.25rem;
  }

  nav[data-toggle] ul a[data-action=toggle][data-toggle] {
    margin-right: 0.5rem;
  }

  nav[data-toggle] > a[data-action=toggle]::before {
    font-size: 1.5rem;
    content: '\f0c9';
  }

  nav[data-toggle] ul a[data-action=toggle]::before {
    font-size: 0.75rem;
    content: '\f054';
  }

  nav[data-toggle] ul a[data-action=toggle][data-toggle]::before {
    content: '\f078';
  }
}

/**
 * Desktop
 */
@media screen and (min-width: 55rem) {
  /**
   * Navigation
   */
  nav[data-toggle] li ul,
  nav[data-toggle] a[data-action=toggle][data-toggle] ~ ul,
  nav[data-toggle] > a[data-action=toggle],
  nav[data-toggle] ul a[data-action=toggle] {
    display: none;
  }

  nav[data-toggle] > ul,
  nav[data-toggle] li:hover > ul,
  nav[data-toggle] > a[data-action=toggle][data-toggle] ~ ul,
  nav[data-toggle] li:hover > a[data-action=toggle][data-toggle] ~ ul {
    display: block;
  }

  nav[data-toggle] ul {
    margin-left: 0;
  }

  nav[data-toggle] > ul {
    margin-top: 0;
  }

  nav[data-toggle] li ul {
    position: absolute;
  }

  nav[data-toggle] li li ul {
    top: 0;
    left: 100%;
  }

  nav[data-toggle] > ul > li {
    display: inline-block;
    margin-right: 0.625rem;
  }

  nav[data-toggle] li li {
    position: relative;
    display: block;
    padding: 0.375rem;
  }
}

/**
 * Print
 */
@media print {
  /**
   * Global
   */
  body {
    padding: 0;
  }

  /**
   * Details
   */
  details {
    margin-bottom: 1.5rem;
  }

  summary {
    display: block;
    margin-bottom: 1.5rem;
    font-size: 1.125rem;
  }

  /**
   * Pagebreak
   */
  h1,
  h2,
  h3,
  summary {
    page-break-after: avoid;
  }

  /**
   * Links
   */
  #content a:not([role]):not([data-action])::after {
    content: " (" attr(href) ")";
    font-size: 0.875rem;
    font-style: italic;
    text-transform: none;
  }
}
