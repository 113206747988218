/**
 * Startseite
 */


.index {
    //display: none;
    min-height: 500px;

    background: $bg-brand-light;
    padding-bottom: 6rem;
    margin-bottom: 0 !important;

    .index-header {
        background-position: center center;
        background-size: cover;
    }

    .index-header-inner {
        position: relative;

        max-width: 70rem;
        min-height: 32.5rem;
        margin: 0 auto;
        padding: 1rem 1rem 0;

        h2 {
            font-size: 1.5rem;
            font-weight: bold;

            position: relative;
            z-index: 5;

            color: $font-color-link;
            margin-bottom: 1.0rem;
            span {
                line-height: 1.6;

                background-color: #fff;
                box-decoration-break: clone; // Fix Firefox
                box-shadow: 0 0 0 4px #fff;
            }
        }
        p {
            font-size: inherit;

            position: relative;
            z-index: 5;

            margin: 0;
            padding: 0 2px;
            //max-width: 40rem;
            span {
                line-height: 2.6;

                color: $font-color-pale;
                background: $bg-secondary;
                background-color: $bg-secondary;
                box-decoration-break: clone; // Fix Firefox
                box-shadow: 0 0 0 6px $bg-secondary;
            }
        }
        .index-person {
            position: absolute;
            z-index: 1;
            top: 1rem;
            bottom: auto;
            left: auto;
            right: -2rem;

            height: 75%;
            width: auto;
            max-width: none;

            // transform: scaleX(-1);
        }
    }

    .index-menu-box {
        position: relative;
        z-index: 10;

        max-width: $max-width + 2*.5rem;
        margin: -5rem auto 0;
        //margin: 0 auto;
        padding: 0 .5rem .5rem;
        .index-menu-box-inner {
            display: flex;
            flex-direction: column;

            max-width: $max-width;
            margin: 0 auto;
            section {
                display: flex;
                flex: 1 1 100%;
                flex-direction: column;

                margin: 0 0 1rem 0;
                padding: 1.0rem;

                color: $font-color-pale;
                background: $bg-brand-pale;
                background: linear-gradient(0deg, $bg-brand-pale 0%, $bg-brand 100%);
                header {
                    flex: 1 0 auto;

                    h3 {
                        font-size: 1.35rem;
                        font-weight: 600;
                        line-height: 1;
                    }
                    h3, p {
                        margin: 0 0 .75rem;
                    }
                }
                a {
                    font-size: 1rem;
                    font-weight: 600;

                    display: inline-block;

                    padding: .6rem;

                    text-decoration: none;

                    color: $font-color-pale;
                    border-radius: 1px;
                    background: $bg-secondary;
                    &:hover {
                        text-decoration: none;

                        background: darken($bg-secondary, 5%);
                    }
                }
            }
        }
    }
}


@media screen and (min-width: $break-desktop) {
    .index {
        .index-header-inner {
            padding: 2.75rem 1rem 0;
            h2 {
                font-size: 2.35rem;
                span {
                    box-shadow: 0 0 0 7px #fff;

                    letter-spacing: .15rem;
                }
            }
            p {
                font-size: 1.4rem;
                span {
                    line-height: 2.5;

                    letter-spacing: .15rem;
                    box-shadow: 0 0 0 9px $bg-secondary;
                }
            }
            .index-person {
                // top: auto;
                // bottom: 0;
                // left: 50%;
                // right: auto;
    
                height: 95%;
                width: auto;
                max-width: none;

            }
        } 
    }
}


@media screen and (min-width: 2*$break-desktop) {
    .index {
        .index-header-inner {
            .index-person {
                top: auto;
                bottom: 0;
                left: 50%;
                right: auto;
            }
        } 
    }
}
    
    
@media screen and (min-width: $break-desktop-header) {
    .index {
        .index-menu-box {
            margin: -4.5rem auto 0;
            .index-menu-box-inner {
                flex-direction: row;
                justify-content: space-between;
                section {
                    display: flex;
                    flex: 0 1 22.5%;
                    padding: 1.5rem;
                    header {
                        flex: 1 0 auto;
                        h3 {
                            font-size: 1.75rem;
                            margin: 0 0 1rem;
                        }
                        p {
                            margin-bottom: 2rem;
                            hyphens: none;
                        }
                    }
                }
            }
        }
}
}
