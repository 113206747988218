.warning {
    color: #fb3e00;
}

#main {
    background: $bg-brand-light;
    //opacity: .5;
    #main-inner {
        //background: red;
        max-width: $max-width;
        margin: 0 auto;
        padding-bottom: 5rem;

        .title-hash {
            background: $bg-secondary;
            display: inline-block;
            margin: 1.2rem 0 1rem 2*$line-height;
            font-size: 1.1rem;
            line-height: 1.8rem;
            color: $font-color-pale;
            padding: 0 $line-height/2;
        }

        #content {

            > h1:first-of-type {
                //background: $bg-secondary;
                //display: inline-block;
                //margin: 4rem 0 $line-height 6.5rem;
                //font-size: 1.5rem;
                //line-height: 2.5rem;
                //color: $font-color-pale;
                //padding: 0 1rem
                margin: 1rem 2*$line-height $line-height/2;
                font-size: 2.3rem;
                line-height: 4rem;
                font-weight: bold;
                color: $font-color-link;
                hyphens: none;
            }

            [data-attr="content"] {
                > p:first-of-type {
                    //margin: 2.1rem 7.5rem $line-height ;
                    //font-size: 3.25rem;
                    //line-height: 4.5rem;
                    //font-weight: bold;
                    //color: $font-color-link;
                    //hyphens: none;
                }
                section.block-content {
                    //display: flex;
                    padding: 0 2rem;
                    margin-top: 3.25rem;
                    align-items: center;
                    //        margin-top: 3rem;
                    //        display: grid;
                    //        grid-template-columns: repeat(2, 1fr);
                    //        grid-template-rows: 1fr;
                    //        grid-column-gap: 0px;
                    //        grid-row-gap: 0px;
                    background: $bg-brand-pale;
                    background: linear-gradient(0deg, $bg-brand-pale 0%, $bg-brand 100%);
                    position: relative;
                    //        background-position: 0px 0px;
                    //        background-repeat:   no-repeat;
                    figure {
                        //flex: 1 1 50%;
                        //margin-top: -10rem;
                        margin-bottom: 0;
                        position: absolute;
                        bottom: 0;
                        max-width: 25%;
                        //            grid-area: 1 / 1 / span 1 / span 1;
                        //            margin: 0;
                        //display: none;
                        img {
                            max-height: 15rem;
                            width: auto;
                        }

                    }
                    > div {
                        //flex: 1 1 50%;
                        padding: 2rem 5% 2rem 30%;
                        min-height: 11rem;
                        hyphens: none;
                        text-shadow: 1px 1px 2px black;
                        position: relative;
                        p {
                            //            grid-area: 1 / 2 / span 1 / span 1;
                            //            align-self: end;
                            //            padding: 4rem 3rem;
                            //            height: 70%;
                            color: $font-color-pale;
                            font-size: 1.3rem;
                            font-style: italic;
                            font-weight: normal;
                            //margin-left: 40%;
                            line-height: 1.75;
                            //
                        }
                    }
                    //        &+ details {
                    //            margin-top: 0;
                    //        }
                }
                details + section.block-content {
                    margin-top: 5rem;
                }

                sup {
                    vertical-align: top;
                    display: inline-block;
                    width: 1rem;
                    height: 1rem;
                    text-indent: -9px;
                    overflow: hidden;
                    background-image: url('/ext/img/info.svg');
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .block-adress, .block-legend {
        background: $bg-brand-light;
        padding: .75rem;

        display: -ms-flexbox !important;;
        display: -webkit-flex !important;;
        display: flex !important;

        align-items: flex-start;

        figure {
            margin: 0 2rem 0 0;

            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            -webkit-align-self: flex-start;
            -ms-flex-item-align: flex-start;
            align-self: flex-start;
        }
        div.content {
            -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
            flex: 0 1 auto;
            -webkit-align-self: center;
            -ms-flex-item-align: center;
            align-self: center;
        }
        p:not(:first-child) {
            margin-top: $line-height/4;
        }
        p:not(:last-child) {
            margin-bottom: $line-height/4;
        }
        &:after {
            content: ".";
            clear: both;
            display: block;
            visibility: hidden;
            height: 0px;
        }
    }
    .block-adress, .block-legend {
        max-width: 40rem;
        + .iframe {
            margin-top: 1.5rem;
        }
    }
    .block-legend {
        max-width: 100%;
    }

    .block-notice {
        //background: $bg-gray-light;
        //padding: 1rem;
        margin: $line-height 0;
        font-size: 0.95rem;
        position: relative;
        h2 {
            margin: -1rem -1rem 1rem -1rem;
            background: $bg-gray;
            border: none;
            padding: .5rem 1rem;
            line-height: 1.5rem;
            color: #000;
            font-size: 1.25rem;
        }
        &:after {
            content: ".";
            clear: both;
            display: block;
            visibility: hidden;
            height: 0px;
        }
        &:before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            text-indent: -9px;
            overflow: hidden;
            background-image: url('/ext/img/info.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            left: -1.5rem;
            top: .25rem;
        }
    }


    a[href*="mehrinput"], a[href*="jetzt_bewerben"] {
        color: $font-color-pale;
        padding: 0.5*$line-height 0.5*$line-height;
        font-weight: bold;
        border-radius: 1px;
        text-decoration: none;
        //margin: $line-height/2 0;
        display: inline-block;
    }
    a[href*="mehrinput"] {
        background: $bg-brand;
        &:hover {
            background: darken($bg-brand, 5%);
        }
    }
    a[href*="jetzt_bewerben"] {
        background: $bg-secondary;
        &:hover {
            background: darken($bg-secondary, 5%);
        }
    }
}

[data-url^="/jetzt_bewerben"], [data-url^="/q-a"] {
    #main {
        #main-inner {
            a[href*="mehrinput"], a[href*="jetzt_bewerben"] {
                color: $font-color-link;
                padding: 0;
                font-weight: normal;
                border-radius: 0;
                text-decoration: underline;
                display: inline;
                background: none;
                &:hover {
                    color: darken($font-color-link, 10%);
                    background: none;
                }
            }
        }
    }        
}

[data-url^="/impressum"], [data-url^="/datenschutz"], [data-url^="/barrierefreiheit"], [data-url^="/barrierefreiheit"] {
    #content {
        div[data-attr="content"] {
            margin-left: 3.5rem;
        }
    }
}

[data-url^="/leichtesprache"] {
    #content {
        aside {
            color: $font-color-link;
            text-align: right;
        }
    }
}



@media screen and (max-width: $break-desktop-header) {
    #main {
        #main-inner {
            .title-hash {
                //margin-top: 2rem;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                font-size: inherit;
                line-height: 1.35rem;
                padding: .25rem .5rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
            #content {
                details {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    summary {
                        margin-left: -1rem;
                        margin-right: -1rem;
                        padding-left: 2.25rem;
                        padding-right: 1rem;
                        &:before {
                            left: 0.5rem;
                        }
                    }
                }
                h1:first-of-type {
                    margin: 1.5rem 0.5rem 2rem;
                    font-size: 1.5rem;
                    line-height: 2.25rem;
                    margin-top: 0.5rem;
                }
                [data-attr="content"] {
                    section.block-content {
                        margin-top: 2rem;
                        padding: 0 0.5rem;
                        figure {
                            position: relative;
                            max-width: 100%;
                            padding: 0 1rem;
                            img {
                                margin-top: -3.5rem;
                                max-width: 100%;
                            }
                        }
                        > div {
                            padding: .5rem;
                            min-height: auto;
                            p {
                                font-size: inherit;
                            }
                        }
                    }
                }
            }
        }

        .block-notice {
            //background: $bg-gray-light;
            //padding: 1rem;
            padding-left: 1.5rem;
            //margin: $line-height 0;
            //font-size: 0.95rem;
            //position: relative;
            //h2 {
            //    margin: -1rem -1rem 1rem -1rem;
            //    background: $bg-gray;
            //    border: none;
            //    padding: .5rem 1rem;
            //    line-height: 1.5rem;
            //    color: #000;
            //    font-size: 1.25rem;
            //}
            //&:after {
            //    content: ".";
            //    clear: both;
            //    display: block;
            //    visibility: hidden;
            //    height: 0px;
            //}
            &:before {
                //content: "";
                //position: absolute;
                //width: 1rem;
                //height: 1rem;
                //text-indent: -9px;
                //overflow: hidden;
                //background-image: url('/ext/img/info.svg');
                //background-size: 100%;
                //background-repeat: no-repeat;
                left: 0;
                //top: .25rem;
            }
        }



        .block-adress, .block-legend {
            background: $bg-brand-light;
            padding: .75rem;

            display: -ms-flexbox !important;;
            display: -webkit-flex !important;;
            display: flex !important;

            align-items: flex-start;

            figure {
                width: 30%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        
    }

    figure.right {
        width: 100%;
        max-width: 100%;
    }
}