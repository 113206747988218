@font-face {
    font-family: 'Cocogoose';
    src: url(/ext/font/Cocogoose-Pro-Light-OTF.otf);
    font-weight: normal;
//    font-style: normal;
//}@font-face {
//    font-family: 'Cocogoose';
//    src: url(/ext/font/Cocogoose-Pro-Light-OTF.otf),
//    url(/ext/font/Cocogoose-Pro-Light-OTF.ttf);
//    font-weight: normal;
//    font-style: italic;
}
@font-face {
    font-family: 'Cocogoose';
    src: url(/ext/font/Cocogoose-Pro-Semilight-OTF.otf);
    font-weight: bold;
    font-style: normal;
}