@import 'variables';

@import 'akilli_base';
@import 'akilli_all';

//@import 'dev';
@import 'font';

@import 'global';
@import 'content';
@import 'menu';
@import 'form';
@import 'kontakt';
@import 'breadcrumb';
@import 'header';
@import 'footer';
@import 'notsupported';
@import 'datenschutz';

@import 'index';
@import 'slider';


@import 'print';
@import 'barriere';
//.skiplinks {
//    position: absolute;
//    top: 0;
//    left: -9999px;
//    height: 1px;
//    width: 1px;
//}