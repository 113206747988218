$max-width: 85.625rem;
$max-width-footer: 45rem;

$break-desktop: 40rem;
$break-desktop-header: 52rem;
$break-pages-with-header-bg: 45rem;

$bg-brand: #035bc5;
$bg-brand-pale: #2ec4e9;
$bg-brand-light: #dff3fa;
$bg-secondary: #79B927;
$bg-secondary-light: #79B927;
$bg-secondary-light: lighten($bg-secondary, 20%);
$bg-gray: #bbb;
$bg-gray-light: lighten($bg-gray, 15%);
$bg-warning: #ff5622;
$bg-footer-icon: #33bee9;

$font-color-secondary: #79B927;
$font-color-pale: #fffcff;
$font-color-link: #005ece;
$font-color-link: #035bc5;
$font-color-placeholder: #39b3e6;
$font-color-menu-social: #2ec4e9;

$line-height: 1.5rem;