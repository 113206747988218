// Hinweis für nichtunterstützte Browser
// -----------------------------------------------
#notsupported {
    background-image: linear-gradient(#feefae,#fae692);
    background-image: linear-gradient(#e63535,#ce3131);
    border-top: 1px solid #8c1c1c;
    position: sticky;
    z-index: 10000;
    bottom: 0;
    padding: 1rem;
    box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.23);
    color: #fff;
    p {
        max-width: $max-width-footer;
        margin: 0 auto;
        position: relative;
        padding-left: 40px;
    }
    svg {
        fill: currentColor;
        position: absolute;
        top: 0.25rem;
        left: 0;
    }
}
.no-csspositionsticky #notsupported { 
    position: fixed;
}
// -----------------------------------------------