/**
 * Global
 */
:root {
    // font-size: 14px;
}

html {
    scroll-behavior: smooth;
    height: 100%;
}

body {
    font-family: 'Cocogoose', serif;
    font-size: 0.9rem;
    line-height: $line-height;

    padding: 8.5rem 0 0;

    color: #000;
    height: 100%;
    // background: #035bc5;
}
body > * {
    //padding: .75rem 0;
    box-sizing: border-box;
}

figure:not(:last-child),
h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child),
p:not(:last-child),
ol:not(:last-child),
ul:not(:last-child),
table:not(:last-child),
main > :not(:last-child),
#content > :not(:last-child):not(details),
.grid > * {
    margin-bottom: $line-height;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
    margin-top: 2*$line-height;
    line-height: 1.125*$line-height;
}

::selection {
    color: #fff;
    background: #000;
}

/**
 * Text
 */
h1 {
    font-size: 2rem;
    line-height: 2*$line-height;
    color: $font-color-link;
    font-weight: bold;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.1rem;
}

p:not(:first-child) {
    margin-top: $line-height/2;
}
p:not(:last-child) {
    margin-bottom: $line-height/2;
}
[data-attr="content"], form, #accept-ds {
    a {
        text-decoration: underline;
        text-decoration-color: $font-color-link;
        text-decoration-thickness: .125em;
        text-underline-offset: 1.5px;
        //background: $bg-brand-light;
        color: $font-color-link;
        transition: all 300ms ease;
        &:hover {
            color: darken($font-color-link, 10%);
            background: #fff;
        }
    }
}
small {
    font-size: 0.9rem;
}

/**
 * Figure
 */
figure.left {
    float: left;

    max-width: 50%;
    margin-right: $line-height;
}

figure.right {
    float: right;

    max-width: 50%;
    margin-left: $line-height;
}

figcaption {
    font-size: .875rem;
}

figure.iframe {
        min-width: 351px;
}

/**
 * List
 */
ol,
ul {
    padding-left: $line-height;
}

/**
 * Nav
 */
nav ul {
    margin: 0;
    padding-left: 0;
}

/**
 * Table
 */
table {
    font-size: .875rem;

    width: 100%;
}

thead {
    border-bottom: solid .0625rem #000;
}

td,
th {
    padding: .71875rem .375rem;
}

th {
    border-bottom: solid .0625rem #000;
}

td {
    border-bottom: solid .0625rem #bbb;
}

table p {
    margin: 0;
}

/**
 * Message
 */
.msg {
    margin: 0 -.75rem;
    padding: .75rem;

    color: #fff;
    background: #000;
}

.msg p {
    margin: 0;
}

em {
    font-weight: normal;
}

/**
 * Grid
 */
.grid figure {
    padding: .25rem;

    text-align: center;

    border: .0625rem solid #ddd;
}

/**
 * Clear
 */
[data-type=rte]::before {
    display: table;

    content: ' ';
}

[data-type=rte]::after {
    display: table;
    clear: both;

    content: ' ';
}

/**
 * Margins, Paddings
 */
#breadcrumb, #content, .news-index, .wrap-blogger {
    max-width: $max-width;
    //margin: 0 auto;
}
//#breadcrumb, #content {
//    padding: 0 1rem;
//}

//@media screen and (max-width: $break-desktop) {
//    #main {
//        padding-bottom: 1.5em;
//        padding-top: 0;
//        padding-left: 0;
//        padding-right: 0;
//
//        display: grid;
//        grid-template-columns: 1fr minmax(0, $max-width) 1fr;
//        grid-template-areas: "m-left index-content m-right";
//        grid-template-rows: 1fr;
//        grid-column-gap: 0px;
//        grid-row-gap: 0px;
//
//
//        #main-inner {
//            grid-area: index-content;
//            padding: 0 .5rem;
//        }
//    }
//}
@media screen and (min-width: $break-desktop) {
    //#main {
    //    //grid-template-columns: minmax(0, 10%) auto minmax(0, 10%);
    //}

    
    figure.iframe-legend {
        min-width: auto;
        width: 50%;
        display: inline-block !important;
    }
    figure.iframe-legend + ul {
        min-width: auto;
        width: 50%;
        display: inline-block !important;
        vertical-align: top;
        padding-left: 5rem;
        margin-top: 2rem;
    }
}

/**
 * Details + Button
 */
details {
    padding: 0.6875rem;
    border: 0.0625rem solid #d3d3d3;
    border-radius: 0.25rem;
}

details + details {
    margin-top: 0.125rem;
}
[role=button],
summary {
    padding: 0.6875rem;
    color: #555;
    background: #e6e6e6;
    border: 0.0625rem solid #d3d3d3;
    border-radius: 0.25rem;
}

[role=button]:hover,
summary:hover {
    color: #212121;
    background: #dadada;
    border-color: #999;
}

summary {
    margin: -0.75rem;
}

details[open] summary {
    margin-bottom: 0.75rem;
}




article#content, .article-content {
//    //h1:first-of-type {
//    //    margin: $line-height/2 0 $line-height;
//    //}
    ul {
        list-style-type:none;
        li {
            position: relative;
            &:not(:first-of-type) {
                padding-top: .15rem;
                margin-top: .15rem;
            }
            &:not(:last-of-type) {
                padding-bottom: .15rem;
                margin-bottom: .15rem;
            }
            &:before {
                color:green;
                font-size: 1em;
                content: url('/ext/img/list-style-check.svg');
                position: absolute;
                left: -1.5rem;
                width: 1rem;
                top: .4rem;
            }
            &:first-of-type {
                &:before {
                    top: .15rem;
                }
            }
            //&:not(:last-of-type) {
            //    padding-bottom: .25rem;
            //}
        }
    }
    h2 {
        color: $font-color-link;
        //padding: .2rem .4rem;
        font-size: 1.1rem;
        font-weight: bold;

        margin: $line-height -2*$line-height ;
        padding: $line-height 3rem 0;

        line-height: $line-height;
        padding-top: 1.5*$line-height;
        padding-left: 2*$line-height;
        padding-right: 2*$line-height;
        padding-bottom: .5*$line-height;
        margin-top: $line-height;
        margin-left: -2*$line-height;
        margin-right: -2*$line-height;
        margin-bottom: .5*$line-height;

        border-top: 3px solid $bg-brand-light;
    }
    h3 {
        margin: 1.5*$line-height 0 0.5*$line-height;
        //color: #000;
        font-weight: bold;
        font-size: 1.0rem;
        font-weight: bold;
    }
    [data-attr="content"] {
        > h2:first-of-type {
            padding-top: 0;
        }
    }
    details {
        display: block;
        margin: 0 0;
        background: #fff;
        border-width: 0;
        padding: 3.25rem 7.5rem 0;
        border-radius: 0;
        //&:after {
            clear: both;
        //}
        > * {
            display: none;
        }
        summary {
            margin: -3.25rem -7.5rem 0;
            border-width: 0;
            border-radius: 0;
            background: #fff;
            color: $font-color-link;
            font-size: 1.25rem;
            padding: $line-height 2*$line-height;
            padding: 0.75rem 1.75rem 0.75rem 4rem;
            display: flex;
            align-items: center;
            position: relative;
            &:before {
                content: '';
                background-image: url('/ext/img/angle-right.svg');
                background-size: 100% 100%;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: .5rem;
                flex-shrink: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 1.75rem;
                margin: auto;
            }
            &::-webkit-details-marker {
                display:none;
            }
        }
        //h2:not(:first-child) {
        //    border-top: 2px solid $bg-brand-light;
        //}
        summary + h2 {
            padding-top: 0;
            border-top-width: 0;
        }
        &[open] {
            padding-bottom: 6rem;
            > * {
                display: block;
            }
            summary {
                margin-bottom: 1.5rem;
                display: flex;
                border-bottom: 0 solid $bg-brand-light;
                &:before {
                    background-image: url('/ext/img/angle-down.svg');
                }
            }
            details {
                summary {
                    margin-bottom: 0;
                    padding-left: 2rem;
                    &:before {
                        background-image: url('/ext/img/angle-right.svg');
                        left: 0;
                    }
                }
                &[open] {
                    padding: 2rem 2.5rem;
                    summary {
                        margin-bottom: 1.5rem;
                        &:before {
                            background-image: url('/ext/img/angle-down.svg');
                        }
                    }
                }
            }
        }
        details {
            padding: 1rem 2.5rem;
            summary {
                border-width: 0;
                padding: 0;
                margin: 0 -2.5rem;
                font-size: 1.1rem;
                font-weight: bold;
                &:before {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: .5rem;
                }
            }
            + details {
                 margin-top: 0;
            }
        }
    }
    details + details {
        margin-top: $line-height;
    }
}
.article-content {
    margin-top: $line-height;
    details {
        summary {
            border-width: 0;
        }
        & + details {
            margin-top: $line-height/2;
        }
        &[open] {
            padding-bottom: $line-height;
        }
    }
}

@media screen and (max-width: $break-desktop-header) {
    article#content, .article-content {
        h2 {
            font-size: 1.1rem;
        }
        h3 {
            margin: 1.0*$line-height 0 0.5*$line-height;
            font-size: 1.0rem;
        }
        details {
            summary {
                font-size: 1.25rem;
                padding-left: 2.25rem;
                &:before {
                    width: 1.35rem;
                    height: 1.35rem;
                }
            }
            details {
                summary {
                    font-weight: normal;
                }
            }
        }
    }
    #main {
        [data-attr="content"] {
            // background: #fff;
            // padding: 2rem .5rem;
            // .block-adress {
            //     max-width: 100%;
            // }
            .block-legend {
                figure {
                    width: auto;
                    margin-right: 1rem;
                    img {
                        width: 30px;
                    }
                }
            }
        }
    }
}






[data-url^="/berufseinstieg"], [data-url^="/ueber_uns"], [data-url^="/q-a"], [data-url^="/praktikum"], [data-url^="/leichtesprache"] {
    #main-inner {
        background-repeat: no-repeat;
        background-position: 95% 3rem;
        background-size: 35%;
        article#content {
            > h1 {
                width: 60%;
            }
            div[data-attr] {
                > p {
                    width: 60%;
                }
            }
        }
    }
}
[data-url^="/berufseinstieg"] {
    #main-inner {
        background-image: url("/ext/img/bg-icon-berufseinstieg.png");
        background-size: 17.5rem auto;
    }
}
[data-url^="/q-a"] {
    #main-inner {
        background-image: url("/ext/img/bg-icon-faq.png");
        background-position: 85% 3rem;
        background-size: 13rem auto;
    }
}
[data-url^="/ueber_uns"] {
    #main-inner {
        background-image: url("/ext/img/bg-icon-ueber_uns.png");
        background-size: 10rem auto;
        article#content {
            > h1 {
                width: 50%;
            }
            div[data-attr] {
                > p {
                    width: 50%;
                }
            }
        }
    }
}
[data-url^="/praktikum"] {
    #main-inner {
        background-image: url("/ext/img/bg-icon-praktikum.png");
        background-position: 85% 3rem;
        background-size: 10rem auto;
        article#content {
            > h1 {
                min-height: 5rem;
            }
        }
    }
}
[data-url^="/leichtesprache"] {
    #main-inner {
        background-image: url("/ext/img/bg-icon-leichte-sprache.png");
        background-position: 85% 3rem;
        background-size: 10rem auto;
        article#content {
            > h1 {
                min-height: 5rem;
            }
        }
    }
}


[data-url^="/q-a"] {
    article#content {
        details[open] {
            ul {
                li {
                    &:before {
                        content: url("/ext/img/list-style-faq.svg");
                    }
                }
            }
        }
    }
}
[data-url^="/unsere_standorte"] {
    [data-attr="content"] {
        background: #fff;
        padding: 2rem 3rem;
        .block-adress {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 1000px) { 
    // [data-url^="/berufseinstieg"], [data-url^="/ueber_uns"], [data-url^="/q-a"] {
    //     #main-inner {
    //         // background-image: none;
    //         background-size: 8rem auto;
    //         background-position: 95% 7rem;
    //         // article#content {
    //         //     > h1 {
    //         //         width: 100%;
    //         //     }
    //         //     div[data-attr] {
    //         //         > p {
    //         //             width: 100%;
    //         //         }
    //         //     }
    //         // }
    //     }
    // }
    [data-url^="/unsere_standorte"] {
        #main {
            [data-attr="content"] {
                background: #fff;
                padding: 2rem .5rem;
                .block-adress {
                    max-width: 100%;
                }
                .block-legend {
                    figure {
                        width: auto;
                        margin-right: 1rem;
                        img {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
    [data-url^="/berufseinstieg"] {
        #main-inner {
            background-size: 8rem auto;
            background-position: 95% 7rem;
        }
    }
    [data-url^="/q-a"] {
        #main-inner {
            background-size: 8rem auto;
            background-position: 90% 5rem;
            article#content {
                > h1 {
                    width: 60%;
                }
            }
        }
    }
    [data-url^="/ueber_uns"] {
        #main-inner {
            background-size: 6.5rem auto;
            background-position: 90% 4rem;
            article#content {
                > h1 {
                    width: 65%;
                }
            }
        }
    }
    [data-url^="/praktikum"] {
        #main-inner {
            background-size: 7rem auto;
            background-position: 90% 4.5rem;
            article#content {
                > h1 {
                    min-height: 3.5rem;
                }
            }
        }
    }
}
