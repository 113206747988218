.form-kontakt, .form-suche {
    margin: $line-height 0;

    fieldset {
        background: #fff;
        padding: $line-height;
        .block-notice {
            margin: 0 0 0 $line-height !important;
        }
    }

    fieldset + fieldset {
        padding-top: 0;
    }

    .form-notice-box {
        padding: $line-height/2;
        border: 1px solid $bg-brand;
    }

    .form-wrap {
        position: relative;
        overflow: hidden;
        label {
            position: absolute;
            left: -9999px;
        }
        &:not(:first-child) {
            margin-top: $line-height/2;
        }
        &:not(:last-child) {
            margin-bottom: $line-height/2;
        }
    }

    input[type="text"], input[type="search"], input[type="url"], input[type="email"], select, textarea, .file-upload-wrapper {
        width: 100%;
        max-width: 50rem;
        background: $bg-brand-light;
        border: 1px solid $bg-brand-light;
        padding: $line-height/2;
        &::-webkit-input-placeholder { /* Edge */
            color: $font-color-placeholder;
            opacity: 1;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $font-color-placeholder;
            opacity: 1;
        }

        &::placeholder {
            color: $font-color-placeholder;
            opacity: 1;
        }
    }
    
    select {
        color: $font-color-placeholder;
        &[data-selected] {
            color: black;
        }
        padding: $line-height/4;
        option {
            padding: $line-height/4;
            color: $font-color-placeholder;
            &.placeholder {
                font-style: italic;
            }
        }
    }
    textarea {
         min-height: 10rem;
    }

    .form-error {
        input, textarea, select, .file-upload-wrapper {
            border-color: $bg-warning;
        }
    }

    button, .btn-reset {
        padding: $line-height/2;
        background: $bg-brand;
        color: #fff;
        font-weight: bold;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        text-decoration: none;
        border: none;
        border-radius: 0;
        &:hover {
            background: darken($bg-brand, 10%);
            text-decoration: none;
        }
        &:disabled {
            background: adjust-color(#998099, $saturation: -50%, $alpha: -0.4);
            &:hover {
                background: adjust-color(#998099, $saturation: -50%, $alpha: -0.4);
            }
        }
    }
    .btn-reset {
        background: $bg-brand-light;
        color: $font-color-link;
        &:hover {
            background: darken($bg-brand-light, 10%);
        }
    }


    li.check-error label {
        border-bottom: 1px solid $bg-warning;
    }
    .form-notice {
        display: block;
        font-size: 0.9rem;
        color: $bg-warning;
    }

    $file-upload-wrapper-height: 3rem;
    .file-upload-wrapper {
        position: relative;
        width: 100%;
        padding: 0;
        height: $file-upload-wrapper-height;
        overflow: hidden;

        &:after {
            content: attr(data-text);
            //font-size: 18px;
            position: absolute;
            top: 0;
            right: 0;
            //background: #fff;
            //padding: 10px 15px;
            padding: 0;
            display: block;
            width: calc(100% - 3rem);
            pointer-events: none;
            z-index: 20;
            height: $file-upload-wrapper-height;
            line-height: $file-upload-wrapper-height;
            //line-height: $file-upload-wrapper-height - 20px;
            color: $font-color-placeholder;
            //border-radius: 5px 10px 10px 5px;
            font-weight: 300;
        }

        &:before {
            content: '+';
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            height: $file-upload-wrapper-height;
            background: darken($bg-brand-light, 20%);
            color: #fff;
            font-weight: 700;
            z-index: 25;
            font-size: 16px;
            line-height: $file-upload-wrapper-height;
            padding: 0 15px;
            //text-transform: uppercase;
            pointer-events: none;
            //border-radius: 0 5px 5px 0;
        }

        &:hover {
            &:before {
                background: darken($bg-brand-light, 30%);
            }
        }
        input {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 99;
            height: 100%;
            margin: 0;
            padding: 0;
            display: block;
            cursor: pointer;
            width: 100%;
        }
    }
    .form-wrap {
        &.upload-hide {
            display: none;
        }
        &.upload-selected {
            .file-upload-wrapper {
                background-color: #fff;
                border: none;
                height: 1.5rem;
                &:before {
                    display: none;
                }
                &:after {
                    width: 100%;
                    line-height: 1.5rem;
                    font-size: .9rem;
                    color: rgb(46, 46, 46);
                    color: $font-color-placeholder;
                    height: 100%;
                }
            }
            &:not(:last-of-type) {
                margin-bottom: 0;
            }
            & + .upload-selected {
                margin-top: 0;
            }
        }
    }

}
.form-suche {
    .content {
        a {
            color: $font-color-link !important;
            padding: 0 !important;
            background: none !important;

        }
    }
    .pager {
        margin-top: 1.5rem;
        text-align: center;
        max-width: 40rem;
        > * {
            float: left;
        }
        .pager-prev, .pager-next {
            padding: $line-height/2;
            background: $bg-brand;
            color: #fff;
        }
        .pager-prev {
            float: left;
        }
        span {
            &.pager-prev, &.pager-next {
                background: $bg-brand-light;
                color: #000;
            }
        }
        .pager-items {
            padding: 0 1rem;
            //padding: $line-height/2;
            > * {
                padding: $line-height/2;
                background: $bg-brand;
                color: #fff;
                display: inline-block;
                margin: 0 .5rem;
            }
            span {
                background: $bg-brand-light;
                color: #000;
            }
        }
        //.pager-next {
        //    float: right;
        //}
    }
}

