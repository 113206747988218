/**
 * Breadcrumb
 */
#breadcrumb {
    display: flex;
    font-size: 0.875rem;
    padding: 2rem 0 .5rem;
    border: 0;
    a {
        color: #fff;
        background: $bg-secondary;
        flex: 0 1 auto;
        //padding: 0 .5rem;
        &[href] {
            color: #fff;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            &:after {
                color: #111;
            }
        }
        &:hover {
            text-decoration: none;
            cursor: default;
        }
        &:not(:last-child) {
            &:after {
                display: inline-block;
                content: '';
                margin: 0 .3rem;
                text-decoration: none;


                border: solid #fff;
                border-width: 0 3px 3px 0;
                padding: 3px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }
        &:first-child {
            padding-left: .5rem;
        }
        &:last-child {
            padding-right: .5rem;
        }
    }
}


@media screen and (min-width: 55rem) {
    #breadcrumb {
        flex-basis: 100%;
    }
}